/* eslint-disable max-lines */
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import api from '../../config/api'
import app from '../../config/app'
import { getFieldTranslations } from '../../helpers/FormHelpers'
import { locationTypeOptions } from '../../graphql/enums/locationType'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { selectors as LinkedinSelectors } from '../../redux/linkedin/redux'
import { selectors as PageSelectors } from '../../pages/Page/redux'
import MarkdownText from '../MarkdownText'
import routes from '../../routes'

import OnBoarding from './index'
import types from './types'


function OnBoardingWrapper(props) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const registerSelector = useSelector(AuthSelectors.register)
  const submitErrors = registerSelector?.errors
  const submitSuccess = registerSelector?.success
  const submitData = registerSelector?.data
  const pageSelector = useSelector(AppSelectors.page)
  const linkedinSelectorData = useSelector(LinkedinSelectors.data)
  const pageData = useSelector(PageSelectors.data)
  const isConnected = useSelector(AuthSelectors.isConnected)

  const onBoardingTableProps = pageData.blocks.find((b) => b.__typename === 'OffersBlock')

  function linkedinGetUrl() {
    const liParams = {
      response_type: 'code',
      client_id: api.LINKEDIN_CLIENT,
      redirect_uri: `${app.APP_URL}/${pageSelector(pageType.ON_BOARDING).link}`,
      state: api.LINKEDIN_STATE,
      scope: 'r_liteprofile,r_emailaddress',
    }

    const liQuery = Object.entries(liParams).map(([key, value]) => `${key}=${encodeURIComponent(value)}`)

    const liUrl = `${api.LINKEDIN_BASE_URI}/authorization?${liQuery.join('&')}`

    return liUrl
  }

  const handleSubmit = (values) => dispatch(AuthActions.register(values))

  const locationField = getFieldTranslations(t, 'onboarding_form_location')

  locationField.options = locationTypeOptions(t)

  //
  // introProps set for integration but need to be dynamic
  //
  const onBoardingProps = {
    introProps: {
      background: 'register/onboarding-header-freemium-ConvertImage_1',
      title: t('onBoarding_intro_title'),
      subtitle: t('onBoarding_intro_subtitle'),
      subscriptionCardFreemium: {
        type: types.FREEMIUM,
        title: t('onBoarding_subscription_card_freemium_title'),
        description: t('onBoarding_subscription_card_freemium_description'),
        price: t('onboarding_subscription_card_price_freemium', { price: '0' }),
        ctaLabel: t('onboarding_subscription_card_cta_freemium'),
        advantages: [
          t('onBoarding_subscription_card_freemium_advantages_1'),
          t('onBoarding_subscription_card_freemium_advantages_2'),
          t('onBoarding_subscription_card_freemium_advantages_3'),
        ],
      },
      subscriptionCardPremium: {
        type: types.PREMIUM,
        title: t('onBoarding_subscription_card_premium_title'),
        description: t('onBoarding_subscription_card_premium_description'),
        price: t('onboarding_subscription_card_price_premium_offer_1', { price: '1100' }),
        price2: t('onboarding_subscription_card_price_premium_offer_2', { price: '550' }),
        ctaLabel: t('onboarding_subscription_card_cta_premium'),
        advantages: [
          t('onBoarding_subscription_card_premium_advantages_1'),
          t('onBoarding_subscription_card_premium_advantages_2'),
          t('onBoarding_subscription_card_premium_advantages_3'),
        ],
        advantagesNote: t('onboarding_subscription_card_advantages_premium_note'),
      },
      onBoardingTableProps,
      isConnected,
      pushRegisterPremium: {
        title: t('onBoarding_push_register_premium_title'),
        ctaLabel: t('onBoarding_push_register_premium_cta_label'),
        ctaRoute: routes.userRegisterPremium.name,
      },
    },
    headerProps: {
      background: 'register/onboarding-header-freemium-ConvertImage_1',
      headerTitle: t('onboarding_header_title'),
    },
    formProps: {
      previousLabel: t('onboarding_previous_label'),
      disclaimerLabel: t('onboarding_disclaimer_label'),
      freemiumTitle: t('onboarding_form_freemium_title'),
      premiumTitle: t('onboarding_form_premium_title'),
      freemiumText: t('onboarding_form_freemium_text'),
      premiumText: t('onboarding_form_premium_text'),
      label: t('onboarding_form_submit_label'),
      fieldsProps: {
        companyName: getFieldTranslations(t, 'onboarding_form_company_name'),
        companyNumber: getFieldTranslations(t, 'onboarding_form_company_number'),
        companyMonacoNumber: getFieldTranslations(t, 'onboarding_form_company_monaco_number'),
        companyTvaEuropeNumber: getFieldTranslations(t, 'onboarding_form_company_tva_europe_number'),
        location: locationField,
        firstName: getFieldTranslations(t, 'onboarding_form_first_name'),
        lastName: getFieldTranslations(t, 'onboarding_form_last_name'),
        email: getFieldTranslations(t, 'onboarding_form_email'),
        password: getFieldTranslations(t, 'onboarding_form_password'),
        cgu: {
          label: <MarkdownText
            text={t('onboarding_form_conditions_label')}
            inline
          />,
          help: t('onboarding_form_conditions_help'),
        },
        optin: getFieldTranslations(t, 'onboarding_form_optin', ['label', 'help']),
      },
      messages: {
        required: t('form_validation_required'),
        checked: t('form_validation_required'),
        email: t('form_validation_email'),
        password: t('form_validation_password'),
        siret: t('form_validation_siret'),
      },
      linkedInConnectPushProps: {
        text: t('register_form_linkedin_text'),
        ctaLabel: t('register_form_linkedin_cta_label'),
        link: linkedinGetUrl() || '',
        linkedInData: linkedinSelectorData,
      },
    },
    confirmProps: {
      title: t('onboarding_confirmation_title'),
      freemiumText: submitData?.message || t('onboarding_confirmation_freemium_text'),
      premiumText: submitData?.message || t('onboarding_confirmation_premium_text'),
      buttonLabel: t('onboarding_confirmation_back_home_label'),
      buttonLink: '/',
      buttonTitle: t('onboarding_confirmation_back_home_title'),
    },
  }

  return (
    <OnBoarding
      {...onBoardingProps}
      onSubmit={handleSubmit}
      submitSuccess={submitSuccess}
      submitErrors={submitErrors}
    />
  )
}

OnBoardingWrapper.propTypes = {
  introCandidate: PropTypes.string,
  introCompany: PropTypes.string,
  promiseCandidate: PropTypes.string,
  promiseCompany: PropTypes.string,
}

OnBoardingWrapper.defaultProps = {
  introCandidate: '',
  introCompany: '',
  promiseCandidate: '',
  promiseCompany: '',
}

export default OnBoardingWrapper
