import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SubscriptionCard = (props) => {
  const classes = useStyles(props)
  // eslint-disable-next-line max-len
  const { className, type, title, description, price, price2, fullPrice, priceNote, ctaLabel, advantages, onTypeClick, advantagesNote } = props

  const handleTypeClick = () => {
    onTypeClick(type)
  }

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          {title}
        </div>
        <MarkdownText
          className={classes.description}
          text={description}
        />
        <div className={classes.priceBox}>
          {fullPrice && (
            <div className={classes.fullPrice}>
              {fullPrice}
            </div>
          )}
          <MarkdownText
            className={cx(classes.price, price2 && classes.smallPrice)}
            text={price}
          />
          {price2 && (
            <MarkdownText
              className={cx(classes.price, classes.price2, classes.smallPrice)}
              text={price2}
            />
          )}
        </div>
        <ActionButton
          className={classes.cta}
          label={ctaLabel}
          onClick={handleTypeClick}
        />
        <div className={classes.priceNote}>
          {priceNote}
        </div>
        <div className={classes.divider} />
        <div className={classes.advantages}>
          <p className={classes.advantagesNote}>{advantagesNote}</p>
          {advantages.map((advantage, index) => (
            <div
              key={index}
              className={classes.advantage}
            >
              <Icon
                className={classes.icon}
                icon={iconsKeys.Check}
                color={colors.tangerine}
              />
              <div className={classes.advantageText}>{advantage}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

SubscriptionCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  price2: PropTypes.string,
  fullPrice: PropTypes.string,
  priceNote: PropTypes.string,
  ctaLabel: PropTypes.string,
  advantages: PropTypes.arrayOf(PropTypes.string),
  onTypeClick: PropTypes.func,
  advantagesNote: PropTypes.string,
}

SubscriptionCard.defaultProps = {
  className: '',
  type: '',
  title: '',
  description: '',
  price: '',
  price2: '',
  fullPrice: '',
  priceNote: '',
  ctaLabel: '',
  advantages: [],
  onTypeClick: () => {},
  advantagesNote: '',
}

export default withMemo()(SubscriptionCard)
