import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SortBox = (props) => {
  const classes = useStyles(props)
  const {
    className,
    placeholder,
    options,
    value,
    onChange,
    isOpen,
    toggleSelect,
    containerRef,
    ulRef,
  } = props

  const handleChange = useCallback((selectedOption) => {
    if (onChange !== null) {
      onChange({ value: selectedOption.value })
    }
  }, [onChange])

  const selectedOption = useMemo(() => value && options?.find((option) => option.value === value), [options, value])

  const renderOptions = useMemo(() => options
    .filter((option) => selectedOption?.value !== option.value)
    .map((option, i) => {
      const isSelected = selectedOption?.value === option.value

      return (
        <li
          key={i}
          className={cx(classes.li, isSelected && classes.liSelected)}
          onClick={() => !isSelected && handleChange(option)}
          role="presentation"
        >
          {option.label}
          {isSelected && (
            <Icon
              icon={iconsKeys.Check}
              color={colors.darkBlue}
              className={classes.icon}
            />
          )}
        </li>
      )
    }), [classes.icon, classes.li, classes.liSelected, handleChange, options, selectedOption])

  if (!options || !options?.length) {
    return null
  }

  return (
    <div
      className={cx(classes.container, className)}
      ref={containerRef}
    >
      <div
        className={classes.placeholder}
        dangerouslySetInnerHTML={{ __html: selectedOption?.label || placeholder }}
        onClick={toggleSelect}
        role="presentation"
      />
      {isOpen && (
        <div className={classes.options}>
          <ul
            className={classes.ul}
            ref={ulRef}
          >
            {renderOptions}
          </ul>
        </div>
      )}
    </div>
  )
}

SortBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleSelect: PropTypes.func,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  ulRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
}

SortBox.defaultProps = {
  className: '',
  value: null,
  onChange: null,
  isOpen: false,
  toggleSelect: () => undefined,
  containerRef: null,
  ulRef: null,
}

export default withMemo()(SortBox)
