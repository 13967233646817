/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import withMemo from '../../decorators/WithMemo'
import { directoryType } from '../../graphql/enums/DirectoryType'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import { colors } from '../../theme'
import CompanyCard from '../CompanyCard'
import FilterBox from '../FilterBox'
import MarkdownText from '../MarkdownText'
import Pagination from '../Pagination'
import ProfileCard from '../ProfileCard'
import SortBox from '../SortBox'
import { ActionButton } from '../index'
import { iconsKeys } from '../Icon/Icon.assets'
import RegisterModal from '../RegisterModal'

import styles from './styles'


const useStyles = createUseStyles(styles)

const DirectoryList = (props) => {
  const {
    className,
    type,
    title,
    subtitle,
    sortBoxProps,
    filterBoxProps,
    entries,
    emptyText,
    pagination,
    handleSubmit,
    isCompany,
  } = props

  const isPremium = useSelector(AuthSelectors.isActivePremium)
  const isConnected = useSelector(AuthSelectors.isConnected)
  const isOGCN = useSelector(AuthSelectors.isOGCN)

  const { t } = useTranslation()

  const [sortValue, setSortValue] = useState(sortBoxProps.value || '')
  const [isSortOpen, setSortOpen] = useState(false)
  const [filterValues, setFilterValues] = useState(filterBoxProps.values || [])
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const classes = useStyles({ ...props, containerNoFlex: !isCompany && !isPremium, isConnected })

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((before) => [
      ...before.filter((item) => item.name !== name),
      {
        name,
        value,
      },
    ])
    setNeedsUpdate(true)
  }, [])

  useEffect(() => {
    setFilterValues(filterBoxProps.values)
  }, [filterBoxProps.values])

  const handleSortChange = useCallback(({ value }) => {
    setSortValue(value)
    setSortOpen(false)
    setNeedsUpdate(true)
  }, [])

  const doSearch = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTimerId(setTimeout(() => {
      handleSubmit({
        sortValue,
        filterValues,
      })
    }, 500))
  }, [handleSubmit, sortValue, filterValues, timerId])

  const handleToggleSorting = () => {
    if (!isSortOpen) {
      setFilterOpen(false)
    }
    setSortOpen(!isSortOpen)
  }

  const handleToggleFiltering = () => {
    if (!isFilterOpen) {
      setSortOpen(false)
    }
    setFilterOpen(!isFilterOpen)
  }

  const Card = useMemo(() => (type === directoryType.COMPANY ? CompanyCard : ProfileCard), [type])
  const renderEntries = useMemo(() => (
    <div className={classes.list}>
      {
        entries?.map((company, i) => (
          <Card
            key={i}
            {...company}
            asCard
            className={classes.card}
            isCurrentOGCN={isOGCN}
          />
        ))
      }
    </div>
  ),
  [classes.card, classes.list, entries, isOGCN])

  const renderEmpty = useMemo(() => (
    <div className={classes.noResults}>{emptyText}</div>
  ), [classes.noResults, emptyText])

  const sortContainerRef = React.createRef()
  const sortUlRef = React.createRef()
  const filterContainerRef = React.createRef()
  const filterSubmenuRef = React.createRef()

  const hasParent = (target, parent, currentRef) => {
    if (!target) {
      return false
    }
    if (target === parent || target.parentElement === currentRef) {
      return true
    }
    return hasParent(target.parentElement, parent, currentRef)
  }

  const handleWindowClick = (e) => {
    if (isSortOpen) {
      if (!hasParent(e.target, sortContainerRef.current, sortUlRef.current)) {
        setSortOpen(false)
      }
    }
    if (isFilterOpen) {
      if (!hasParent(e.target, filterContainerRef.current, filterSubmenuRef.current)) {
        setFilterOpen(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  })

  useEffect(() => {
    if (needsUpdate) {
      setNeedsUpdate(false)
      doSearch()
    }
  }, [needsUpdate, doSearch])


  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.header}>
        {title && (
          <MarkdownText
            tag="h1"
            text={title}
            className={classes.title}
            inline
          />
        )}
        {subtitle && (
          <MarkdownText
            text={subtitle}
            className={classes.subtitle}
            inline
          />
        )}
        {isPremium && (
          <FilterBox
            {...filterBoxProps}
            className={classes.filterBlock}
            isOpen={isFilterOpen}
            toggleSelect={() => handleToggleFiltering()}
            values={filterValues}
            onChange={({ name, value }) => handleFilterChange(name, value)}
            containerRef={filterContainerRef}
            submenuRef={filterSubmenuRef}
          />
        )}
      </div>
      <div className={classes.content}>
        <div className={isConnected || isCompany ? classes.wrapper : classes.wrapperNoFlex}>
          <div className={classes.filters}>
            <SortBox
              {...sortBoxProps}
              className={classes.filterBlock}
              value={sortValue}
              isOpen={isSortOpen}
              onChange={handleSortChange}
              toggleSelect={() => handleToggleSorting()}
              containerRef={sortContainerRef}
              ulRef={sortUlRef}
            />
          </div>

          {(isPremium && !isCompany) || isCompany ? (
            <>
              <div className={classes.results}>
                {entries?.length > 0 ? renderEntries : renderEmpty}
              </div>

              {entries?.length > 0 && (
                <Pagination {...pagination} />
              )}
            </>
          ) : (
            <ActionButton
              className={classes.register}
              label={t('directory_member_cta_register')}
              icon={iconsKeys.SmallArrowRight}
              iconRight
              onClick={() => setModalOpen(true)}
              colorIcon={colors.pureWhite}
              colorIconHover={colors.darkBlue}
            />
          )}

          <RegisterModal
            modalTitle={t('directoryList_view_members_title_unauthenticated')}
            modalText={t('directoryList_view_members_text_unauthenticated')}
            modalCta={t('directoryList_view_members_cta_unauthenticated')}
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            premiumStep
          />
        </div>
      </div>
    </div>
  )
}

DirectoryList.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  emptyText: PropTypes.string,
  sortBoxProps: PropTypes.shape(SortBox.propTypes),
  filterBoxProps: PropTypes.shape(FilterBox.propTypes),
  entries: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape(CompanyCard.propTypes),
  ])),
  emptyCompaniesText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  handleSubmit: PropTypes.func,
  order: PropTypes.string,
  isCompany: PropTypes.bool,
}

DirectoryList.defaultProps = {
  className: '',
  type: null,
  title: null,
  subtitle: null,
  emptyText: null,
  sortBoxProps: null,
  filterBoxProps: null,
  entries: [],
  emptyCompaniesText: null,
  pagination: null,
  handleSubmit: () => undefined,
  order: '',
  isCompany: true,
}

export default withMemo()(DirectoryList)
